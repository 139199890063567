<i18n>
	{
		"en": {
			"forgotPass": "Forgot Password",
			"goToLogin": "Go To Login Page",
			"enterNumber": "Enter mobile number",
			"confirm": "Confirm",
			"otpSentTo": "OTP code sent at ",
			"enterOtp": "Please enter OTP code.",
			"requestAgain": "Request Code Again",
			"enterPass": "Enter New Password",
			"confirmPass": "Verify New Password",
			"confirm": "Confirm",
			"back": "Back"
		},
		"np":{
			"forgotPass": "पासवर्ड बिर्सनुभयो",
			"goToLogin": "लगइन पृष्ठमा जानुहोस्",
			"enterNumber": "मोबाइल नम्बर राख्नुहोस्",
			"confirm": "पुष्टि गर्नुहोस्",
			"otpSentTo": "OTP कोड पठाइयो ",
			"enterOtp": "कृपया OTP कोड राख्नुहोस्।",
			"requestAgain": "कोड पुन: अनुरोध गर्नुहोस्",
			"enterPass": "नयाँ पासवर्ड राख्नुहोस्",
			"confirmPass": "नयाँ पासवर्ड प्रमाणित गर्नुहोस्",
			"confirm": "पुष्टि गर्नुहोस्",
			"back": "पछाडि"
			}
	}
</i18n>
<template lang='pug'>
	.hero-banner
		.hero-banner-content
			.container
				.login-modal.login-box
					.modal-header
						h6.font-weight-bold {{ $t("forgotPass") }}
						small
							router-link(to="/login").text-dark.font-weight-bold {{ $t("goToLogin") }}
					.modal-body
						.show-message(v-if='message') {{message}}
						.row
							.col-sm-12.col-lg-6
								transition(name='fade' mode='out-in')
									form(@submit.prevent='sendOTP' v-if='!verificationSent')
										.form-group
											.input-group
												.input-group-prepend
													.input-group-text +977
												input.form-control(type='tel' pattern='[0-9]+' v-model='user.phoneNumber'
													:placeholder='$t("enterNumber")' autofocus required
													minlength='10' maxlength='10')
										.text-center.mt-4
											button.btn.btn-danger.btn-block(:disabled='loading')
												.text-center
													.label(v-if='!loading') {{ $t("confirm") }}
													b-spinner(small v-else)
									div(v-else)
										form(@submit.prevent='submitVerificationCode')
											.form-group
												.text-muted {{$t("otpSentTo")}} {{user.phoneNumber}}
											.form-group
												label {{ $t("enterOtp") }}
												input.form-control(v-model='verificationCode' type="number" name="otp_code" autocomplete="off" placeholder='6 digit code' required)
												.div.mt-2
													u.text-dark.font-weight-bold.cursor-pointer(@click='sendOTP') {{ $t("requestAgain") }}
											.form-group
												label {{ $t("enterPass") }}
												input.form-control(v-model='user.password' name="new_password" type='password'
													minlength="6" required)
											.form-group
												label {{ $t("confirmPass") }}
												input.form-control(v-model='user.confirmPassword' type='password'
													minlength="6" required)
											.row.mt-2
												.col-6
													button.btn.btn-danger.btn-block(type='submit' :disabled='loading')
														.text-center
															.label(v-if='!loading') {{ $t("confirm") }}
															b-spinner(small v-else)
												.col-6
													button.btn.btn-secondary.btn-block(type='button' @click='verificationSent = false' :disabled='loading') {{ $t("back") }}
</template>

<script>
import { REMOTE_SERVER_1, prepareHeaders } from "@/helpers/general";
import axios from "@/axios"
export default {
	name: "recover",
	data() {
		return {
			user: {
				phoneNumber: "",
				password: "",
				confirmPassword: "",
				countrycode: "+977"
			},
			error: null,
			verificationSent: false,
			verificationCode: "",
			message: "",
			loading: false
		};
	},
	methods: {
		async sendOTP() {
			this.loading = true;
			let response = await axios.post(
				REMOTE_SERVER_1 + "user/password/forget",
				{
					phoneNumber: this.user.countrycode + this.user.phoneNumber
				}
			);
			if (response.data && response.data.code == 1) {
				this.verificationSent = true;
				this.user.token = response.data.data.token;
			} else if (response.data.message) {
				this.message = "Number is not registered yet.";
			}
			this.loading = false;
		},
		submitVerificationCode() {
			if (this.user.password !== this.user.confirmPassword) {
				this.message = "Passwords don't match";
				return;
			}
			this.loading = true;
			axios
				.post(
					REMOTE_SERVER_1 + "user/password/change",
					{
						code: this.verificationCode,
						password: this.user.password,
						name: this.user.fullName
					},
					{
						headers: {
							"reset-token": this.user.token
						}
					}
				)
				.then(r => {
					this.loading = false;
					if (r.data.code === 1) {
						this.message = "You can login with your new password now.";
					} else {
						this.message = "Could not reset your password.";
					}
				});
		}
	}
};
</script>

<style scoped>
.error {
	text-align: center;
	color: red;
}
</style>

